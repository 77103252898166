import '../utils/styles.css';

function View() {
  return (
      <iframe 
      style={{ height: '100vh', width: '100%'}}
      src="https://web.archive.org/web/20230413055828/https://www.adhunikradio.com/" 
      title="Adhunik Radio"
      ></iframe>
  );
}

export default View;
